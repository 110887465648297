import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import GoogleAd from './GoogleAd';
import Adsense from 'react-adsense';
const Wrapper = styled.div`
    min-width: 20rem;
    width: 20rem;
    height: 22rem;

    ${tw`flex flex-wrap bg-grey-light rounded-lg overflow-hidden p-1 mx-1`};
`
const AdContainer = styled.div`
    ${tw`bg-grey-light border-2 mx-auto`};
    height: 22rem;
    width: 20rem;
    ${props => props.responsive && `
        height: 250px;
        width: 100%;
    `}
`
const Advertisement = styled.p`
    ${tw`font-semibold italic text-grey-dark opacity-20 mt-2`};
`
var sectionStyle = {
    width: "25rem",
    display:'table-cell',
    borderRadius:'.5rem',
    textAlign:'center',
    verticalAlign:'middle'
};

const AdCardInDetailPage = (props) => (
    <div style={sectionStyle}>
        <Adsense.Google
            client={props.client}
            slot={props.slot}
            style={props.style}
            layout={props.layout}
            format={props.format}
        />
    </div> 
);

export default AdCardInDetailPage;